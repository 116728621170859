.draft {
  min-height: 100%;
  padding: 0;
}

.draft__controls {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.draft__editor {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.draft__editor .info-box {
  width: 90%;
  max-width: 900px;
  margin: 10px auto 0 auto;
}

.document {
  position: relative;
  display: flex;
  flex-direction: column;
}

.document {
  flex: 1;
}

.document__header {
  width: 45vw;
  max-width: 800px;
  margin: 20px auto;
  padding: auto;
}

.document--readonly .document__header {
  padding-right: 0;
}

.document__editor {
  display: flex;
  height: 100%;
}

.document__editor--document > .toolbox {
  margin-top: -64px;
}

.document__editor--document > .outline-wrapper {
  margin-top: -64px;
}

.editor {
  flex: 1;
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-size: 15px;
  line-height: 1.3;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);

  counter-reset: footnote;
}

.document--readonly {
  margin-top: 20px;
}

.editor section > section > .title {
  font-size: 1.6rem;
}

.editor section > section > section > .title {
  font-size: 1.4rem;
}

.editor section > section > section > section > .title {
  font-size: 1.2rem;
}

.editor .glossary {
  margin-top: 40px;
}

.editor.show-suggestions [data-slate-leaf][data-suggestion] {
  position: relative;
}

.editor.show-suggestions [data-slate-leaf][data-suggestion]::before,
.editor.show-suggestions [data-slate-leaf][data-suggestion]::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--blue);
}

.editor.show-suggestions [data-slate-leaf][data-suggestion]::after {
  top: auto;
  bottom: 0;
}

.editor.show-suggestions [data-slate-leaf][data-suggestion=insert] {
  color: #008000;
}

.editor.show-suggestions [data-slate-leaf][data-suggestion=insert]::before,
.editor.show-suggestions [data-slate-leaf][data-suggestion=insert]::after {
  background-color: #008000;
}

.editor.show-suggestions [data-slate-leaf][data-suggestion=remove] {
  color: #9E0000;
  text-decoration: line-through;
}

.editor.show-suggestions [data-slate-leaf][data-suggestion=remove]::before,
.editor.show-suggestions [data-slate-leaf][data-suggestion=remove]::after {
  background-color: #9E0000;
}

.editor.show-suggestions [data-slate-node][data-suggestion="insert"] {
  border: 1px solid #008000;
}

.editor.show-suggestions [data-slate-node][data-suggestion="remove"] {
  border: 1px solid #9E0000;
}

.editor.show-suggestions li[data-suggestion="insert"]::marker {
  color: #008000;
}
.editor.show-suggestions li[data-suggestion="remove"]::marker {
  color: #9E0000;
}
.editor.show-suggestions li[data-suggestion="remove"] > .move-source:only-child::before {
  content: ' ';
}
.editor.show-suggestions li[data-suggestion="remove"]:has(> .move-source:only-child) {
  line-height: 0.4em;
}

.editor:not(.show-suggestions) [data-suggestion=remove] {
  display: none;
}

.editor a {
  cursor: pointer;
  color: blue;
}

.editor p {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
}

.editor ul {
  padding-left: 30px;
  list-style-type: disc;
}

.editor ol {
  padding-left: 30px;
  list-style-type: decimal;
}

.editor ul[data-style="open-circle"] {
  list-style-type: circle;
}

.editor ul[data-style="pilcrow"] {
  list-style-type: "\00b6 ";
}

.editor ul[data-style="rpilcrow"] {
  list-style-type: "\204b ";
}

.editor ul[data-style="asterisk"] {
  list-style-type: "* "; /* TODO: probably not * exactly */
}

.editor ul[data-style="dash"] {
  list-style-type: "- "; /* TODO: probably not - exactly */
}

.editor ol[data-style="upper-alpha"] {
  list-style-type: upper-alpha;
}

.editor ol[data-style="lower-alpha"] {
  list-style-type: lower-alpha;
}

.editor ol[data-style="upper-roman"] {
  list-style-type: upper-roman;
}

.editor ol[data-style="lower-roman"] {
  list-style-type: lower-roman;
}

.editor sub, .editor sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.editor sup {
  top: -0.5em;
}

.editor sub {
  bottom: -0.25em;
}

.editor em {
  font-style: italic;
}

.editor pre {
  overflow: auto;
}

.editor pre {
  margin: 10px 0;
}

.editor pre, .editor code {
  font-family: 'Source Code Pro', monospace;
  color: #222;
  padding: 0 3px;
  border-radius: 3px;
  background-color: #e3e3e3;
}

.editor .source {
  font-family: 'Source Code Pro', monospace;
  color: var(--font-light);
  border-radius: 3px;
  background-color: var(--dark-bg);
  white-space: pre-wrap;
}

.editor .source--block {
  display: block;
  padding: 3px;
  margin: 10px 0;
}

.editor .source--inline {
  display: inline-block;
  padding: 0 3px;
  line-height: 1;
}

.editor figure {
  margin: 0;
  padding-left: 48q;
  padding-right: 48q;
  display: flex;
  flex-direction: column;
}

.editor .media__alt,
.editor .media__text {
  margin: 10px 0;
}

.editor .media__alt::before,
.editor .media__text::before {
  content: attr(data-label);
  padding-right: 5px;
  color: gray;
}

.editor img,
.editor .video,
.editor .audio {
  display: block;
  margin: 0 auto;
  /* By changing padding from 2px to 0 when rendering selection we ensure
   * the total width doesn't change, avoiding layout ‘jumping’ when user
   * selects an image.
   */
  padding: 2px;
  max-width: 100%;
}

.editor img[data-selected=true],
.editor .video[data-selected=true],
.editor .audio[dagta-selected=true] {
  border: 2px solid var(--dark-bg);
  padding: 0;
}

.editor .title {
  /* Use same margins for titles as for paragraphs. */
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* Don't use bold for titles. */
  font-weight: normal;
  font-size: 1.8rem;
}

.editor .admonition > .title,
.editor figure > .title,
.editor .rule > .title,
.editor blockquote > .title {
  font-size: 1.5rem;
}

.glossary > .title {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.editor .admonition {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  border-inline-start: 4px solid var(--color);
  margin-inline-start: calc(var(--margin-inline-start) - 12px);
  padding-inline-start: 8px;
}

.editor .admonition > .admonition-title:first-child {
  user-select: none;
  display: block;
  color: gray;
}

.editor .admonition[data-type="note"] {
  --color: gray;
}

.editor .admonition[data-type="warning"] {
  --color: yellow;
}

.editor .admonition[data-type="tip"] {
  --color: gray;
}

.editor .admonition[data-type="important"] {
  --color: red;
}

.editor .exercise {
  border-inline-start: 4px solid gray;
  margin-inline-start: calc(var(--margin-inline-start) - 12px);
  padding-inline-start: 8px;
}

.editor .exercise ::before {
  display: block;
  color: gray;
}

.editor .exercise * > :first-child {
  margin-top: 0;
}

.editor .exercise .exercise-problem::before {
  content: attr(data-label);
}

.editor .exercise .exercise-solution::before {
  content: attr(data-label);
}

.editor .exercise .exercise-commentary::before {
  content: attr(data-label);
}

.editor .rule {
  border-inline-start: 4px solid gray;
  margin-inline-start: calc(var(--margin-inline-start) - 12px);
  padding-inline-start: 8px;
}g

.editor .rule::before,
.editor .rule ::before {
  display: block;
  color: gray;
}

.editor .rule .title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.editor .rule * > :first-child {
  margin-top: 0;
}

.editor .rule::before,
.editor .rule .rule-statement::before,
.editor .rule .rule-proof::before,
.editor .rule .rule-example::before {
  content: attr(data-label);
}

div.editor figure > figcaption::before {
  content: attr(data-label);
}

.editor .footnote {
  color: gray;
  line-height: 0;
  counter-increment: footnote;
}

.editor .footnote:before {
  content: "(";
  display: inline;
}

.editor .footnote:after {
  content: ")";
  display: inline;
}

.editor .footnote.collapse > * {
  font-size: 0;
}

.editor .footnote.collapse:before {
  content: counter(footnote);
  position: relative;
  top: -0.3em;
  padding: 2px;
  cursor: pointer;
}

.editor .footnote.collapse:after {
  content: none;
}

.editor .foreign {
  font-style: italic;
  border: 1px solid var(--font-dark);
  padding: 0 2px;
}

.editor .foreign::before {
  display: inline;
  content: attr(data-flag);
  padding-right: 4px;
}

.editor .adr-table {
  width: 100%;
  margin: 2rem 0;
  border-bottom: 2px solid #ddd;
}

.editor .adr-table .title {
  font-weight: 700;
  display: table-caption;
  caption-side: bottom-outside;
  padding: 1rem 0;
}

.editor .adr-table .caption {
  display: table-caption;
  caption-side: bottom-outside;
  padding: 1rem 0;
}

.editor .adr-table .caption::before {
  content: "Table caption: ";
  font-weight: 700;
}

.editor .adr-table tgroup {
  width: 100%;
}

.editor .adr-table thead td {
  font-weight: 700;
  border-bottom: 2px solid #ddd;
}

.editor .adr-table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.editor .adr-table td {
  padding: 0.5rem 1rem;
}

.editor blockquote {
  border-inline-start: 4px solid gray;
  padding-inline-start: 4px;
  font-style: italic;
}

.editor .term{
  border: 1px solid var(--font-dark);
  padding: 0 2px;
}

.editor .definition-term {
  display: inline-block;
  font-weight: 700;
}

.editor .definition {
  counter-reset: meaning;
  margin-bottom: 10px;
}

.editor .definition::before {
  display: inline-block;
  content: attr(data-label) ': ';
  font-weight: 700;
}

.editor .definition-meaning {
  margin-left: 25px;
  position: relative;
}

.editor .definition-meaning::before {
  counter-increment: meaning;
  content: counter(meaning) '. ';
  position: absolute;
  left: -15px;
  font-weight: 700;
}

.editor .definition-example {
  position: relative;
  display: block;
  margin: 15px 0 10px 0;
  padding-left: 10px;
  border: 1px solid var(--dark-bg);
}

.editor .definition-example::before {
  position: absolute;
  top: -9px;
  left: 7px;
  content: attr(data-label);
  background-color: #fff;
  padding: 0 3px;
  font-weight: 700;
}

.editor .definition-seealso::before {
  content: attr(data-label) ": ";
  font-weight: 700;
  margin-left: 10px;
}

.editor .definition-seealso .definition-term {
  display: inline-flex;
  margin-right: 4px;
}

.editor .definition-seealso .definition-term::after {
  content: ',';
}

.editor .definition-seealso .definition-term:last-child::after {
  content: none;
}

.editor .reference-invalid {
  background-color: #9E0000;
  color: var(--font-light);
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
}

.editor .reference-invalid:before {
  content: "⚠";
  display: inline;
  color: var(--font-light);
}

.editor .equation [disabled] {
  opacity: 1;
}

.editor .missing-image {
  text-align: center;
}

.editor .missing-image .missing-image__message {
  color: var(--orange);
  font-size: 20px;
  font-weight: 700;
}

.editor.show-suggestions .split-point::before {
  content: "\00b6";
  color: #008000;
}

.editor.show-suggestions .join::before {
  content: "\00b6";
  color: #9E0000;
  text-decoration: line-through;
}
